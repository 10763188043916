export default function Contact() {
  return (
    <div className="bg-gray-50" id="kontakt">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Skaffa recept</span>
            <span className="block">Ring vår svenska läkare</span>
          </h2>
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            <span className="block pt-4">070-832 66 76</span>
          </h3>
          <h3>
            <span className="block">Öppet 08-22</span>
          </h3>
        </div>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="tel:+46708326676"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-teal-600 px-5 py-3 text-base font-medium text-white hover:bg-teal-700"
            >
              Ring direkt
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
