import { PhoneIcon, ClipboardDocumentCheckIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Ring vår svenska läkare',
    description:
      'Vid förskrivning av recept ställs ett fåtal frågor om hälsotillstånd via telefon.',
    icon: PhoneIcon,
  },{
    name: 'E-recept direkt',
    description:
      'Receptet finns tillgängligt på apotek inom 60 min',
    icon: ClipboardDocumentCheckIcon,
  },{
    name: 'Hämta ut recept',
    description:
      'E-receptet finns för uthämtning på valfritt Svenskt apotek.',
    icon: ShoppingBagIcon,
  },
]

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32 lg:py-30" id='sa-gar-det-till'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-semibold leading-8 text-teal-600">Så här går det till</h2>
        </div>

        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-3 md:gap-x-12 md:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} >
                <div className='flex items-center justify-center py-10'>
                    <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-teal-500 text-white sm:shrink-0">
                    <feature.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                </div>
                <div className="sm:min-w-0 sm:flex-1 text-center">
                  <p className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p className="mt-2 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
