import Menu from "./Menu";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Facts from "./sections/Facts";
import Features from "./sections/Features";
import Hero from "./sections/Hero";

const Layout = () => 
    <div className="isolate bg-white">
        <header>
            <Menu></Menu>
        </header>
        <main>
            <Hero></Hero>
            <Features></Features>
            <Contact></Contact>
            <Facts></Facts>
            <About></About>
        </main>
    </div>

export default Layout;