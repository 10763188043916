import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Kan man köpa Sildenafil/Viagra receptfritt i Sverige?',
    description:
      'Nej tyvärr. Det är alltid receptbelagt och du betalar fullpris dvs ingen läkemedelsrabatt.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Kan man köp Sildenafil från utlandet på nätet och utan recept?',
    description:
    'På nätet finns många bolag som säljer SIldenafil/ Viagra. Att beställa ifrån ett icke EU land är förbjudet. Läkemedelsverket uppger att det finns risk för oäkta preparat. Att beställa ifrån ett EU land kräver oftast recept och blir oftast dyrare än vårt alternativ.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Finns det biverkningar med att ta Sildenafil/Viagra?',
    description:
    'Det är ovanligt med biverkningar av dessa preparat. Det vanligaste är ansiktsrodnad, värmekänsla i ansiktet och nästäppa. Vi hänvisar till Läkemedelsverkets- och Svenska apotekskedjors hemsidor för ytterligare information. Se även bipackssedel före användande.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Hur länge verkar en tablett?',
    description:
      'Det varierar mycket mellan olika personer men normalt är 4-8 timmar med start ca 30-45 min efter intag av utprovad dos.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Hur fungerar Sildenafil/Viagra?',
    description:
      'Den glatta muskulaturen i penis svällkroppar tar emot en större mängd blod med Sildenafil, men normalt bara efter sexuell stimulering. Penis ökar då tydligt i storlek och hårdhet, ollonet blir ofta också större samt lätt rodnat och kärlen direkt under huden sväller lite grand. Många upplever dessa symtom som mycket positiva.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Blir man beroende av Sildenafil?',
    description:
      'Du blir inte fysiskt beroende av Sildenafil. Dom positiva effekterna av Sildenafil på din penis och ditt sexliv skulle kunna sägas ge ett psykiskt beroende.',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Fungerar Sildenafil alltid?',
    description:
      'Om omständigheterna är rätt fungerar alltid Sildenafil. Vi ber dig därför att vid "rätt" tillfällen prova dig fram till att fysiskt och psykiskt få ut det mesta av din nya "livsstilsmedicin".',
    icon: QuestionMarkCircleIcon,
  },
]

export default function Facts() {
  return (
    <div className="bg-white py-24 sm:py-32 lg:py-40" id='fakta'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <h2 className="text-lg font-semibold leading-8 text-teal-600">Fakta och Råd</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Här har vi samlat vanliga frågor</p>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
            Vid andra frågor eller råd, kontakta vår svenska läkare <a href="/#kontakt">här</a>
          </p>
        </div>

        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-teal-500 text-white sm:shrink-0">
                  <feature.icon className="h-8 w-8" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p className="mt-2 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
