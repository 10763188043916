export default function About() {
  return (
    <div className="bg-gray-50 py-24 sm:py-32 lg:py-40" id='om-oss'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <h2 className="text-lg font-semibold leading-8 text-teal-600 mb-6">Om oss</h2>
          <div className="flex justify-center">
            <img className="w-40 h-40 rounded-full" src="/jan_gertoft.jpeg" alt="Rounded avatar"/>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
          Hej, <br/>
          Mitt namn är Jan Gertoft, pensionerad allmänläkare, men har nu en s.k. fritidspraktik.
Jag har många års erfarenhet av att skriva ut recept för Sildenafil och jag vet att användandet av Sildenafil/Viagra ökar i Sverige. Genom att <a href="/#kontakt">kontakta mig på telefon</a> får du nu möjlighet att snabbt, billigt och enkelt få tillgång till Sildenafil via valfritt apotek i Sverige.
          </p>
        </div>
      </div>
    </div>
  )
}
